import gql from "graphql-tag";

import { companyFragment } from "../../../fragments";

export default gql`
  mutation createCompanyPublic(
    $firstName: String!
    $lastName: String!
    $paymentMethodId: ID
    $companyName: String!
    $email: AWSEmail!
    $password: String!
    $promoId: ID
    $companyId: ID!
    $plaid: PlaidInput
  ) {
    createCompanyPublic(
      firstName: $firstName
      lastName: $lastName
      paymentMethodId: $paymentMethodId
      companyName: $companyName
      email: $email
      password: $password
      promoId: $promoId
      companyId: $companyId
      plaid: $plaid
    ) {
      ...companyFragment
    }
  }
  ${companyFragment}
`;
