import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Input,
  ListItem,
  Text,
  useTheme,
} from "@ui-kitten/components";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { toast } from "react-toastify";

import Button from "../Button";
import Separator from "../Separator";
import getActivePromoCode from "../../api/functions/getActivePromoCode";
import { products } from "../../constants";
import getFormattedAmount from "../../utils/getFormattedAmount";
import { StripePromotionCode } from "../../types";

const styles = StyleSheet.create({
  plan: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  promotionCodeContainer: {
    alignItems: "center",
    flexDirection: "row",
    minHeight: 48,
  },
  promotionCodeLabel: {
    paddingStart: 20,
  },
  promotionCodeName: {
    flex: 1,
    textAlign: "right",
  },
  promotionCodeSearchButton: {
    marginBottom: 4,
  },
  promotionCodeSearchContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  totalContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});

const useStyles = makeStyles({
  paper: {
    borderLeft: (props) => `4px solid ${props.color}`,
  },
});

const getTotalAmount = (promotionCode?: StripePromotionCode) => {
  const talariumCorePrice = products.core.pricing.price;
  if (promotionCode) {
    const {
      coupon: { amount_off, percent_off },
    } = promotionCode;
    if (amount_off) {
      return talariumCorePrice - amount_off;
    } else if (percent_off) {
      return (
        talariumCorePrice - Math.round((talariumCorePrice * percent_off) / 100)
      );
    }
  }
  return talariumCorePrice;
};

interface Props {
  onApplyPromotionCode: (promotionCode: StripePromotionCode) => void;
  promotionCode?: StripePromotionCode;
}

const OrderSummary = ({ onApplyPromotionCode, promotionCode }: Props) => {
  const theme = useTheme();
  const classes = useStyles({ color: theme["color-primary-default"] });

  const [promotionCodeSearch, setPromotionCodeSearch] = useState<{
    errorMessage?: string;
    loading: boolean;
    text: string;
  }>({
    errorMessage: null,
    loading: false,
    text: "",
  });

  return (
    <>
      <Paper className={classes.paper}>
        <Separator size="small" />
        <ListItem
          accessoryRight={() => {
            return (
              <View style={{ alignItems: "flex-end", marginHorizontal: 8 }}>
                <Text>Starting at</Text>
                <Text>
                  {`${getFormattedAmount(products.core.pricing.price)} / month`}
                </Text>
              </View>
            );
          }}
          disabled
          style={styles.plan}
          title="Talarium Core"
        />
        <Separator size="small" />
      </Paper>
      <Separator size="medium" />
      {promotionCode ? (
        <View style={styles.promotionCodeContainer}>
          <Text category="s2" style={styles.promotionCodeLabel}>
            Promotion
          </Text>
          <Text style={styles.promotionCodeName}>
            {promotionCode.coupon.name}
          </Text>
        </View>
      ) : (
        <>
          <Text category="label">Add a promotion code</Text>
          <Separator size={4} />
          <View style={styles.promotionCodeSearchContainer}>
            <Input
              onChangeText={(text) =>
                setPromotionCodeSearch((prevState) => ({ ...prevState, text }))
              }
              status={promotionCodeSearch.errorMessage ? "danger" : "basic"}
              value={promotionCodeSearch.text}
            />
            <Separator horizontal size="medium" />
            <Button
              loading={promotionCodeSearch.loading}
              onPress={async () => {
                setPromotionCodeSearch((prevState) => ({
                  ...prevState,
                  errorMessage: null,
                  loading: true,
                }));
                try {
                  const activePromotionCode = await getActivePromoCode(
                    promotionCodeSearch.text
                  );
                  if (activePromotionCode) {
                    onApplyPromotionCode(activePromotionCode);
                    setPromotionCodeSearch((prevState) => ({
                      ...prevState,
                      data: activePromotionCode,
                      loading: false,
                    }));
                    toast("Promotion code applied!", { type: "success" });
                  } else {
                    setPromotionCodeSearch((prevState) => ({
                      ...prevState,
                      errorMessage:
                        "The promotional code you entered is not valid.",
                      loading: false,
                    }));
                  }
                } catch (error) {
                  setPromotionCodeSearch((prevState) => ({
                    ...prevState,
                    errorMessage: error.message,
                    loading: false,
                  }));
                }
              }}
              status="basic"
              style={styles.promotionCodeSearchButton}
            >
              Apply
            </Button>
          </View>
          {promotionCodeSearch.errorMessage && (
            <Text category="c1" status="danger">
              {promotionCodeSearch.errorMessage}
            </Text>
          )}
        </>
      )}
      <Separator size="medium" />
      <Divider />
      <Separator size="medium" />
      <View style={styles.totalContainer}>
        <Text category="s1">Due today</Text>
        <Separator horizontal />
        <Text category="s1">
          {getFormattedAmount(getTotalAmount(promotionCode))}
        </Text>
      </View>
    </>
  );
};

export default OrderSummary;
