import { Button, Text } from "@ui-kitten/components";
import React from "react";
import { openPopupWidget } from "react-calendly";
import { StyleSheet, View } from "react-native";

import Separator from "../../components/Separator";

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: "flex-start",
  },
  calendly: {
    width: "100%",
    height: 1000,
  },
});

interface Props {
  email: string;
  firstName: string;
  lastName: string;
}

const SuccessScreen = ({ email, firstName, lastName }: Props) => {
  return (
    <View>
      <Separator />
      <Text category="h5">Thank you for your purchase.</Text>
      <Separator size="small" />
      <Text>
        Talarium is now available for your use. You will receive an email
        confirmation of this transaction and an additional welcome email.{" "}
        <a href="https://app.talarium.io">
          Click here to go to the Talarium app.
        </a>
      </Text>
      <Separator />
      <Text category="h6">
        Schedule your onboarding now to get up and running as fast as possible.
      </Text>
      <Separator />
      <View style={styles.buttonContainer}>
        <Button
          onPress={() =>
            openPopupWidget({
              url: "https://calendly.com/talarium/training",
              prefill: { name: `${firstName} ${lastName}`, email },
            })
          }
        >
          Schedule Onboarding
        </Button>
      </View>
      <Separator />
    </View>
  );
};

export default SuccessScreen;
