import gql from "graphql-tag";

import { jsonResponseFragment } from "../../../fragments";

export default gql`
  query getActivePromoCode($code: String!) {
    getActivePromoCode(code: $code) {
      ...jsonResponseFragment
    }
  }
  ${jsonResponseFragment}
`;
