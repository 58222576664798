import { Button, Divider, Input, Text } from "@ui-kitten/components";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { toast } from "react-toastify";
import * as yup from "yup";

import Separator from "../../components/Separator";

interface Props {
  initialValues: {
    companyName?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
  };
  onSuccess: (values: {
    companyName: string;
    firstName: string;
    lastName: string;
  }) => void;
  visible: boolean;
}

const styles = StyleSheet.create({
  headerText: {
    fontSize: 18,
  },
});

const CompanyAndUserInfoScreen = ({
  initialValues,
  onSuccess,
  visible,
}: Props) => {
  const formik = useFormik({
    initialValues: {
      companyName: "",
      firstName: "",
      lastName: "",
    },
    onSubmit: (values) =>
      onSuccess({
        companyName: values.companyName,
        firstName: values.firstName,
        lastName: values.lastName,
      }),
    validationSchema: yup.object().shape({
      companyName: yup.string().required("Required"),
      firstName: yup.string().required("Required"),
      lastName: yup.string().required("Required"),
    }),
  });

  useEffect(() => {
    if (visible) {
      formik.resetForm({
        values: {
          companyName: initialValues.companyName,
          firstName: initialValues.firstName,
          lastName: initialValues.lastName,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  useEffect(() => {
    if (formik.submitCount > 0 && !formik.isValid) {
      toast("Submission failed. Please fix the remaining errors.", {
        type: "error",
      });
    }
  }, [formik.isValid, formik.submitCount]);
  return (
    <View>
      <Separator />
      <Text category="p2">
        STEP <Text category="label">1</Text> OF <Text category="label">5</Text>
      </Text>
      <Text category="h5">Basic information</Text>
      <Separator size="small" />
      <Text appearance="hint">
        The following information is needed before we can create your account.
      </Text>
      <Separator />
      <Text style={styles.headerText}>Personal information</Text>
      <Separator size="small" />
      <Divider />
      <Separator size="medium" />
      <Input
        caption={formik.touched.firstName && formik.errors.firstName}
        label="Full name"
        onBlur={formik.handleBlur("firstName")}
        onChangeText={formik.handleChange("firstName")}
        onSubmitEditing={() => formik.handleSubmit()}
        placeholder="First name"
        status={
          formik.touched.firstName && formik.errors.firstName
            ? "danger"
            : "basic"
        }
        value={formik.values.firstName}
      />
      <Separator size="small" />
      <Input
        caption={formik.touched.lastName && formik.errors.lastName}
        onBlur={formik.handleBlur("lastName")}
        onChangeText={formik.handleChange("lastName")}
        onSubmitEditing={() => formik.handleSubmit()}
        placeholder="Last name"
        status={
          formik.touched.lastName && formik.errors.lastName ? "danger" : "basic"
        }
        value={formik.values.lastName}
      />
      <Separator />
      <Text style={styles.headerText}>Company information</Text>
      <Separator size="small" />
      <Divider />
      <Separator size="medium" />
      <Input
        caption={formik.touched.companyName && formik.errors.companyName}
        label="Company name"
        onBlur={formik.handleBlur("companyName")}
        onChangeText={formik.handleChange("companyName")}
        onSubmitEditing={() => formik.handleSubmit()}
        status={
          formik.touched.companyName && formik.errors.companyName
            ? "danger"
            : "basic"
        }
        value={formik.values.companyName}
      />
      <Separator size={48} />
      <Button onPress={() => formik.handleSubmit()}>Continue</Button>
      <Separator />
    </View>
  );
};

export default CompanyAndUserInfoScreen;
