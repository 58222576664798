import mutation from "./graphql";
import client from "../../client";

const createCompanyPublic = async (input: {
  companyId: string;
  companyName: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  paymentMethodId: string;
  plaid?: {
    accountId: string;
    token: string;
  };
  promoId?: string;
}) => {
  const {
    companyId,
    companyName,
    email,
    firstName,
    lastName,
    password,
    paymentMethodId,
    plaid,
    promoId,
  } = input;
  await client.mutate({
    mutation,
    variables: {
      firstName,
      lastName,
      paymentMethodId,
      companyName,
      email,
      password,
      promoId,
      companyId,
      plaid,
    },
  });
};

export default createCompanyPublic;
