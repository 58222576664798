import { Icon, ListItem, Text, useTheme } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, View } from "react-native";

import Separator from "../../components/Separator";
import { PaymentMethod } from "../../types";

interface Props {
  onSuccess: (paymentMethod: PaymentMethod) => void;
}

const styles = StyleSheet.create({
  listItem: {
    borderRadius: 8,
    borderWidth: 1,
  },
});

const SelectPaymentMethodScreen = ({ onSuccess }: Props) => {
  const theme = useTheme();
  return (
    <View>
      <Separator />
      <Text category="p2">
        STEP <Text category="label">4</Text> OF <Text category="label">5</Text>
      </Text>
      <Text category="h5">Choose a payment method</Text>
      <Separator />
      <ListItem
        accessoryLeft={(imageProps) => (
          <Icon {...imageProps} name="credit-card" pack="MaterialIcons" />
        )}
        accessoryRight={(imageProps) => (
          <Icon {...imageProps} name="arrow-ios-forward-outline" />
        )}
        onPress={() => onSuccess("card")}
        style={[
          styles.listItem,
          { borderColor: theme["border-basic-color-3"] },
        ]}
        title="Credit or Debit card"
      />
      <Separator size="small" />
      <ListItem
        accessoryLeft={(imageProps) => (
          <Icon {...imageProps} name="bank" pack="MaterialCommunityIcons" />
        )}
        accessoryRight={(imageProps) => (
          <Icon {...imageProps} name="arrow-ios-forward-outline" />
        )}
        onPress={() => onSuccess("ach")}
        style={[
          styles.listItem,
          { borderColor: theme["border-basic-color-3"] },
        ]}
        title="ACH Direct Debit"
      />
    </View>
  );
};

export default SelectPaymentMethodScreen;
