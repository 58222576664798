export const products = {
  additionalLicenses: {
    pricing: {
      price: 2499,
    },
  },
  core: {
    pricing: {
      price: 49999,
    },
  },
};

export const states = {
  AL: {
    abbreviation: "AL",
    name: "Alabama",
  },
  AK: {
    abbreviation: "AK",
    name: "Alaska",
  },
  AZ: {
    abbreviation: "AZ",
    name: "Arizona",
  },
  AR: {
    abbreviation: "AR",
    name: "Arkansas",
  },
  CA: {
    abbreviation: "CA",
    name: "California",
  },
  CO: {
    abbreviation: "CO",
    name: "Colorado",
  },
  CT: {
    abbreviation: "CT",
    name: "Connecticut",
  },
  DE: {
    abbreviation: "DE",
    name: "Delaware",
  },
  DC: {
    abbreviation: "DC",
    name: "District of Columbia",
  },
  FL: {
    abbreviation: "FL",
    name: "Florida",
  },
  GA: {
    abbreviation: "GA",
    name: "Georgia",
  },
  HI: {
    abbreviation: "HI",
    name: "Hawaii",
  },
  ID: {
    abbreviation: "ID",
    name: "Idaho",
  },
  IL: {
    abbreviation: "IL",
    name: "Illinois",
  },
  IN: {
    abbreviation: "IN",
    name: "Indiana",
  },
  IA: {
    abbreviation: "IA",
    name: "Iowa",
  },
  KS: {
    abbreviation: "KS",
    name: "Kansas",
  },
  KY: {
    abbreviation: "KY",
    name: "Kentucky",
  },
  LA: {
    abbreviation: "LA",
    name: "Louisiana",
  },
  ME: {
    abbreviation: "ME",
    name: "Maine",
  },
  MD: {
    abbreviation: "MO",
    name: "Maryland",
  },
  MA: {
    abbreviation: "MA",
    name: "Massachusetts",
  },
  MI: {
    abbreviation: "MI",
    name: "Michigan",
  },
  MN: {
    abbreviation: "MN",
    name: "Minnesota",
  },
  MS: {
    abbreviation: "MS",
    name: "Mississippi",
  },
  MO: {
    abbreviation: "MO",
    name: "Missouri",
  },
  MT: {
    abbreviation: "MT",
    name: "Montana",
  },
  NE: {
    abbreviation: "NE",
    name: "Nebraska",
  },
  NV: {
    abbreviation: "NV",
    name: "Nevada",
  },
  NH: {
    abbreviation: "NH",
    name: "New Hampshire",
  },
  NJ: {
    abbreviation: "NJ",
    name: "New Jersey",
  },
  NM: {
    abbreviation: "NM",
    name: "New Mexico",
  },
  NY: {
    abbreviation: "NY",
    name: "New York",
  },
  NC: {
    abbreviation: "NC",
    name: "North Carolina",
  },
  ND: {
    abbreviation: "ND",
    name: "North Dakota",
  },
  OH: {
    abbreviation: "OH",
    name: "Ohio",
  },
  OK: {
    abbreviation: "OK",
    name: "Oklahoma",
  },
  OR: {
    abbreviation: "OR",
    name: "Oregon",
  },
  PA: {
    abbreviation: "PA",
    name: "Pennsylvania",
  },
  RI: {
    abbreviation: "RI",
    name: "Rhode Island",
  },
  SC: {
    abbreviation: "SC",
    name: "South Carolina",
  },
  SD: {
    abbreviation: "SD",
    name: "South Dakota",
  },
  TN: {
    abbreviation: "TN",
    name: "Tennessee",
  },
  TX: {
    abbreviation: "TX",
    name: "Texas",
  },
  UT: {
    abbreviation: "UT",
    name: "Utah",
  },
  VT: {
    abbreviation: "VT",
    name: "Vermont",
  },
  VA: {
    abbreviation: "VA",
    name: "Virginia",
  },
  WA: {
    abbreviation: "WA",
    name: "Washington",
  },
  WV: {
    abbreviation: "WV",
    name: "West Virginia",
  },
  WI: {
    abbreviation: "WI",
    name: "Wisconsin",
  },
  WY: {
    abbreviation: "WY",
    name: "Wyoming",
  },
  AS: {
    abbreviation: "AS",
    name: "American Samoa",
  },
  GU: {
    abbreviation: "GU",
    name: "Guam",
  },
  MP: {
    abbreviation: "MP",
    name: "N. Mariana Islands",
  },
  PR: {
    abbreviation: "PR",
    name: "Puerto Rico",
  },
  VI: {
    abbreviation: "VI",
    name: "Virgin Islands",
  },
};
