import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Text } from "@ui-kitten/components";
import React, { useState } from "react";
import { View } from "react-native";
import { toast } from "react-toastify";

import createCompanyPublic from "../../api/functions/createCompanyPublic";
import PaymentMethodForm from "../../components/PaymentMethodForm";
import Separator from "../../components/Separator";
import config from "../../config";
import { PaymentMethod } from "../../types";

interface Props {
  initialValues: {
    companyId: string;
    companyName?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
  };
  onSuccess: () => void;
  paymentMethod: PaymentMethod;
  visible: boolean;
}

const stripePromise = loadStripe(config.stripe);

const Stripe = ({
  initialValues,
  onSuccess,
  paymentMethod,
  visible,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const {
    companyId,
    companyName,
    email,
    firstName,
    lastName,
    password,
  } = initialValues;
  const runCreateCompanyPublic = async ({
    paymentMethodId,
    plaid,
    promoId,
  }: {
    paymentMethodId?: string;
    plaid?: { accountId: string; token: string };
    promoId?: string;
  }) => {
    setLoading(true);
    try {
      await createCompanyPublic({
        companyId,
        companyName,
        email,
        firstName,
        lastName,
        password,
        paymentMethodId,
        plaid,
        promoId,
      });
      onSuccess();
    } catch (error) {
      toast(`Submission failed. ${error.message}`, {
        type: "error",
      });
    }
    setLoading(false);
  };
  return (
    <View>
      <Separator />
      <Text category="p2">
        STEP <Text category="label">5</Text> OF <Text category="label">5</Text>
      </Text>
      <Text category="h5">Checkout</Text>
      <Separator />
      <Elements stripe={stripePromise}>
        <PaymentMethodForm
          companyId={companyId}
          loading={loading}
          onAchSuccess={({ plaid, promoId }) =>
            runCreateCompanyPublic({ plaid, promoId })
          }
          onCardSuccess={({ paymentMethodId, promoId }) =>
            runCreateCompanyPublic({ paymentMethodId, promoId })
          }
          paymentMethod={paymentMethod}
          visible={visible}
        />
      </Elements>
      <Separator />
    </View>
  );
};

export default Stripe;
