import * as eva from "@eva-design/eva";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import {
  ApplicationProvider,
  Divider,
  Icon,
  IconRegistry,
  Layout,
  TopNavigation,
  TopNavigationAction,
} from "@ui-kitten/components";
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import Amplify from "aws-amplify";
import Constants from "expo-constants";
import { StatusBar } from "expo-status-bar";
import LogRocket from "logrocket";
import React, { useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import * as Sentry from "sentry-expo";
import { v4 as uuidv4 } from "uuid";

import MaterialCommunityIconsPack from "./src/components/icons/MaterialCommunityIconsPack";
import MaterialIconsPack from "./src/components/icons/MaterialIconsPack";
import ToastContainer from "./src/components/ToastContainer";
import config from "./src/config";
import useModalScreens from "./src/hooks/useModalScreens";
import useScript from "./src/hooks/useScript";
import CompanyAndUserInfoScreen from "./src/screens/CompanyAndUserInfo";
import EmailAndPassword from "./src/screens/EmailAndPassword";
import PlanPickerScreen from "./src/screens/PlanPicker";
import SelectPaymentMethodScreen from "./src/screens/SelectPaymentMethod";
import Stripe from "./src/screens/Stripe";
import SuccessScreen from "./src/screens/Success";
import { PaymentMethod } from "./src/types";

Amplify.configure(config.aws);
Sentry.init({
  beforeSend: (event) => {
    const sessionUrl = LogRocket.sessionURL;
    if (sessionUrl) {
      return {
        ...event,
        extra: {
          ...event.extra,
          sessionURL: sessionUrl,
        },
      };
    }
    return event;
  },
  dsn:
    "https://24f3899bd7064d4a8bc6f711eea765f1@o364106.ingest.sentry.io/5549475",
  enableAutoSessionTracking: true,
  enableInExpoDevelopment: true,
  environment: config.sentry.environment,
  release: `web@v${Constants.manifest.version}`,
});
if (!__DEV__) {
  LogRocket.init("sue0ee/talarium-sign-up");
}

const companyId = uuidv4();

const styles = StyleSheet.create({
  root: {
    height: "100vh",
    overflow: "hidden",
    width: "100vw",
  },
  tabView: {
    height: "100%",
    overflow: "hidden",
  },
});

const useStyles = makeStyles({
  container: {
    paddingLeft: 16,
    paddingRight: 16,
  },
});

// @ts-ignore
window.$crisp = [];
// @ts-ignore
window.CRISP_WEBSITE_ID = "dd6e291f-516e-49db-8fda-acd9b1cfe54a";

const App = () => {
  useScript("https://client.crisp.chat/l.js");

  const classes = useStyles();
  const { currentScreen, goBack, jumpTo } = useModalScreens();

  const [createCompanyInput, setCreateCompanyInput] = useState<{
    companyId: string;
    companyName?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
  }>({ companyId });
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>("card");
  useScript("https://client.crisp.chat/l.js");

  const renderScreen = () => {
    switch (currentScreen) {
      case "companyAndUserInfo":
        return (
          <CompanyAndUserInfoScreen
            initialValues={createCompanyInput}
            onSuccess={({ companyName, firstName, lastName }) => {
              setCreateCompanyInput((prevState) => ({
                ...prevState,
                companyName,
                firstName,
                lastName,
              }));
              jumpTo("emailAndPassword");
            }}
            visible
          />
        );
      case "emailAndPassword":
        return (
          <EmailAndPassword
            initialValues={createCompanyInput}
            onSuccess={({ email, password }) => {
              setCreateCompanyInput((prevState) => ({
                ...prevState,
                email,
                password,
              }));
              jumpTo("planPicker");
            }}
            visible
          />
        );
      case "planPicker":
        return (
          <PlanPickerScreen onSuccess={() => jumpTo("selectPaymentMethod")} />
        );
      case "selectPaymentMethod":
        return (
          <SelectPaymentMethodScreen
            onSuccess={(selectedPaymentMethod) => {
              setPaymentMethod(selectedPaymentMethod);
              jumpTo("stripe");
            }}
          />
        );
      case "stripe":
        return (
          <Stripe
            initialValues={createCompanyInput}
            onSuccess={() => jumpTo("success")}
            paymentMethod={paymentMethod}
            visible
          />
        );
      case "success":
        return (
          <SuccessScreen
            email={createCompanyInput.email}
            firstName={createCompanyInput.firstName}
            lastName={createCompanyInput.lastName}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* eslint-disable-next-line react/style-prop-object */}
      <StatusBar style="auto" />
      <IconRegistry
        icons={[EvaIconsPack, MaterialCommunityIconsPack, MaterialIconsPack]}
      />
      <ApplicationProvider {...eva} theme={eva.light}>
        <Layout style={styles.root}>
          <Container disableGutters maxWidth="sm">
            <TopNavigation
              accessoryLeft={() => (
                <TopNavigationAction
                  icon={(imageProps) => {
                    switch (currentScreen) {
                      case "emailAndPassword":
                      case "planPicker":
                      case "selectPaymentMethod":
                      case "stripe":
                        return (
                          <Icon {...imageProps} name="arrow-back-outline" />
                        );
                      default:
                        return null;
                    }
                  }}
                  onPress={goBack}
                />
              )}
              alignment="center"
              title={() => (
                <img
                  alt="Company logo"
                  height="56"
                  /* eslint-disable-next-line global-require */
                  src={require("./assets/logo.png")}
                />
              )}
            />
          </Container>
          <Divider />
          <ScrollView>
            <Container
              className={classes.container}
              disableGutters
              maxWidth="sm"
            >
              {renderScreen()}
            </Container>
          </ScrollView>
          <ToastContainer />
        </Layout>
      </ApplicationProvider>
    </>
  );
};

export default App;
