import { useState } from "react";

import { Screens } from "../../types";

const useModalScreens = () => {
  const [currentScreen, setCurrentScreen] = useState<Screens>(
    "companyAndUserInfo"
  );

  const jumpTo = (screenName: Screens) => setCurrentScreen(screenName);

  const goBack = () => {
    switch (currentScreen) {
      case "emailAndPassword": {
        setCurrentScreen("companyAndUserInfo");
        break;
      }
      case "planPicker": {
        setCurrentScreen("emailAndPassword");
        break;
      }
      case "selectPaymentMethod": {
        setCurrentScreen("planPicker");
        break;
      }
      case "stripe": {
        setCurrentScreen("selectPaymentMethod");
        break;
      }
      default: {
        break;
      }
    }
  };

  return { currentScreen, goBack, jumpTo };
};

export default useModalScreens;
