import { Icon } from "@ui-kitten/components";
import React, { useState } from "react";
import { toast } from "react-toastify";

import Button from "../Button";
import PlaidLink from "../PlaidLink";
import createPlaidLinkToken from "../../api/functions/createPlaidLinkToken";

interface Props {
  companyId: string;
  onSuccess: (public_token, metadata) => void;
}

const PlaidButton = ({ companyId, onSuccess }: Props) => {
  const [linkToken, setLinkToken] = useState<string>();
  const [loading, setLoading] = useState(false);
  return (
    <>
      {linkToken && (
        <PlaidLink
          linkToken={linkToken}
          onExit={(error) => {
            setLoading(false);
            if (error) {
              toast(error?.display_message, { type: "error" });
            }
          }}
          onSuccess={(public_token, metadata) => {
            setLoading(false);
            onSuccess(public_token, metadata);
          }}
        />
      )}
      <Button
        accessoryLeft={(imageProps) => (
          <Icon {...imageProps} name="bank" pack="MaterialCommunityIcons" />
        )}
        loading={loading}
        onPress={async () => {
          setLoading(true);
          setLinkToken(null);
          try {
            setLinkToken(await createPlaidLinkToken(companyId));
          } catch (error) {
            setLoading(false);
            toast(error?.message, { type: "error" });
          }
        }}
        status="basic"
      >
        Connect a bank account
      </Button>
    </>
  );
};

export default PlaidButton;
