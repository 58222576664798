import gql from "graphql-tag";

export const companyFragment = gql`
  fragment companyFragment on Company {
    id
  }
`;

export const jsonResponseFragment = gql`
  fragment jsonResponseFragment on JsonResponse {
    response
  }
`;
