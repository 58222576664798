import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Divider, Text } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { toast } from "react-toastify";

import Button from "../Button";
import CardInput from "../CardInput";
import OrderSummary from "../OrderSummary";
import PlaidButton from "../PlaidButton";
import PlaidLinkListItem from "../PlaidLinkListItem";
import Separator from "../Separator";
import { PaymentMethod, PlaidLinkItem, StripePromotionCode } from "../../types";

interface Props {
  companyId: string;
  loading: boolean;
  onAchSuccess: (values: {
    plaid: {
      accountId: string;
      token: string;
    };
    promoId?: string;
  }) => void;
  onCardSuccess: (values: {
    paymentMethodId: string;
    promoId?: string;
  }) => void;
  paymentMethod: PaymentMethod;
  visible: boolean;
}

const styles = StyleSheet.create({
  achAgreementContainer: {
    borderRadius: 8,
    borderWidth: 1,
    paddingEnd: 16,
    paddingStart: 16,
  },
  headerText: {
    fontSize: 18,
  },
});

const PaymentMethodForm = ({
  companyId,
  loading,
  onAchSuccess,
  onCardSuccess,
  paymentMethod,
  visible,
}: Props) => {
  const elements = useElements();
  const stripe = useStripe();

  const [plaidLinkItem, setPlaidLinkItem] = useState<PlaidLinkItem>();
  const [createPaymentMethodLoading, setCreatePaymentMethodLoading] = useState(
    false
  );
  const [promotionCode, setPromotionCode] = useState<StripePromotionCode>();

  useEffect(() => {
    if (elements) {
      const cardElement = elements.getElement(CardElement);
      if (!visible && cardElement) {
        // This is to deal with a bug that occurs when going back one screen while the CardElement
        // is focused. Omitting this breaks the TabView.
        cardElement.blur();
      }
    }
  }, [visible]);
  useEffect(() => {
    if (visible && paymentMethod === "card") {
      setPlaidLinkItem(null);
    }
  }, [paymentMethod, visible]);

  const promoId = promotionCode?.id;
  const runCardSubmission = async () => {
    if (!stripe || !elements) {
      return;
    }
    setCreatePaymentMethodLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      card: elements.getElement(CardElement),
      type: "card",
    });
    if (error) {
      toast(error.message, { type: "error" });
    } else {
      onCardSuccess({
        paymentMethodId: paymentMethod.id,
        promoId,
      });
    }
    setCreatePaymentMethodLoading(false);
  };
  const runAchSubmission = async () => {
    if (plaidLinkItem) {
      onAchSuccess({
        plaid: {
          accountId: plaidLinkItem.metadata.accounts[0].id,
          token: plaidLinkItem.publicToken,
        },
        promoId,
      });
    } else {
      toast("Please connect a bank account", { type: "error" });
    }
  };

  return (
    <>
      {paymentMethod === "card" && (
        <>
          <Text style={styles.headerText}>Add a credit or debit card</Text>
          <Separator size="small" />
          <Divider />
          <Separator size="medium" />
          <CardInput />
          <Separator />
        </>
      )}
      {paymentMethod === "ach" && (
        <>
          {plaidLinkItem ? (
            <>
              <Text>The following account will be used:</Text>
              <Separator size="small" />
              <PlaidLinkListItem linkItem={plaidLinkItem} />
            </>
          ) : (
            <>
              <Text style={styles.headerText}>Connect your account</Text>
              <Separator size="small" />
              <Divider />
              <Separator size="medium" />
              <Text>
                After clicking on the button below, you will be asked to sign
                into your financial institution account and choose which account
                you wish to connect via our partner Plaid.
              </Text>
              <Separator />
              <PlaidButton
                companyId={companyId}
                onSuccess={(public_token, metadata) =>
                  setPlaidLinkItem({ metadata, publicToken: public_token })
                }
              />
            </>
          )}
          <Separator />
        </>
      )}
      <Text style={styles.headerText}>Review order</Text>
      <Separator size="small" />
      <Divider />
      <Separator size="medium" />
      <OrderSummary
        onApplyPromotionCode={setPromotionCode}
        promotionCode={promotionCode}
      />
      <Separator />
      {paymentMethod === "card" && (
        <Typography variant="caption">
          By clicking Purchase, you acknowledge you have read and agreed to the{" "}
          <Link
            href="https://talarium.io/terms-of-service/"
            target="_blank"
            underline="always"
          >
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link
            href="https://talarium.io/privacy-policy/"
            target="_blank"
            underline="always"
          >
            Privacy Policy
          </Link>
          .
        </Typography>
      )}
      {paymentMethod === "ach" && (
        <>
          <Typography variant="caption">
            By clicking Purchase, you acknowledge the following:
          </Typography>
          <Typography variant="caption">
            {"\u2022 "}I have read and agree to the{" "}
            <Link
              href="https://talarium.io/terms-of-service/"
              target="_blank"
              underline="always"
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              href="https://talarium.io/privacy-policy/"
              target="_blank"
              underline="always"
            >
              Privacy Policy
            </Link>
            .
          </Typography>
          <Typography variant="caption">
            {"\u2022 "}I authorize Kontra Technologies Inc. D.B.A. Talarium to
            electronically debit my account and, if necessary, electronically
            credit my account to correct erroneous debits. I also understand I
            can revoke access by emailing support@talarium.io.
          </Typography>
        </>
      )}
      <Separator />
      <Button
        loading={createPaymentMethodLoading || loading}
        onPress={
          paymentMethod === "card" ? runCardSubmission : runAchSubmission
        }
      >
        Purchase
      </Button>
    </>
  );
};

export default PaymentMethodForm;
