import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { ToastContainer as ReactToastifyToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles({
  toastContainer: {
    borderRadius: 8,
    overflow: "hidden",
  },
});

const ToastContainer = () => {
  const classes = useStyles();
  return (
    <ReactToastifyToastContainer toastClassName={classes.toastContainer} />
  );
};

export default ToastContainer;
