import { AUTH_TYPE } from "aws-appsync";

const dev = {
  aws: {
    Auth: {
      identityPoolId: "us-west-2:3ada6ff7-7fea-498d-b0f0-1e59b03d601c",
      region: "us-west-2",
      userPoolId: "us-west-2_obFOgoo4S",
      userPoolWebClientId: "7ke0kh9flaf6u905gkshnb2n16",
    },
    aws_appsync_authenticationType: AUTH_TYPE.AWS_IAM,
    aws_appsync_graphqlEndpoint:
      "https://n73hwv67kff3hkisyxdvo2l6mm.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: "us-west-2",
  },
  sentry: {
    environment: "development",
  },
  stripe:
    "pk_test_51HtHYbJFZdxVHgWqNI4IWbPhSmM3p6wuiYZUKy1xyjVkJGst9NwcZlyHrMAxWoJ72AQe6DS5YqWsjyOKg4Mk7rCM00MRbdzBFv",
};

const staging = {
  aws: {
    Auth: {
      identityPoolId: "us-west-2:f4a2d642-b8a1-4279-86fe-26828fd26a91",
      region: "us-west-2",
      userPoolId: "us-west-2_JaCzk8xmg",
      userPoolWebClientId: "3pbr5bj4m3gs28nkgk51i0lps3",
    },
    aws_appsync_authenticationType: AUTH_TYPE.AWS_IAM,
    aws_appsync_graphqlEndpoint:
      "https://aoutxyytjnfp3cqzvz6rrfoskq.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: "us-west-2",
  },
  sentry: {
    environment: "staging",
  },
  stripe:
    "pk_test_51HtHYbJFZdxVHgWqNI4IWbPhSmM3p6wuiYZUKy1xyjVkJGst9NwcZlyHrMAxWoJ72AQe6DS5YqWsjyOKg4Mk7rCM00MRbdzBFv",
};

const prod = {
  aws: {
    Auth: {
      identityPoolId: "us-west-2:14c3c9ae-aab7-44e5-91cf-f18d873644b4",
      region: "us-west-2",
      userPoolId: "us-west-2_M1WhW2Bz3",
      userPoolWebClientId: "3mklp3n0lees3f7a21sistf27a",
    },
    aws_appsync_authenticationType: AUTH_TYPE.AWS_IAM,
    aws_appsync_graphqlEndpoint:
      "https://phmrdcghujh5le3ka6w6efu3sq.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: "us-west-2",
  },
  sentry: {
    environment: "production",
  },
  stripe:
    "pk_live_51HtHYbJFZdxVHgWqlMCAaj3PaulsFWR0SUBPW1SCMv6xJuvB1lN518cZtZeGZmKIbEVh07Uks1LhYPoCgWAYarvW00fh14hppt",
};

let config = dev;
if (!__DEV__) {
  config = prod;
}

export default config;
