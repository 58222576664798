import mutation from "./graphql";
import client from "../../client";

const createPlaidLinkToken = async (companyId: string) => {
  const { data } = await client.mutate<{
    createPlaidLinkToken: { linkToken: string };
  }>({
    mutation,
    variables: {
      companyId,
    },
  });
  return data.createPlaidLinkToken.linkToken;
};

export default createPlaidLinkToken;
