import query from "./graphql";
import client from "../../client";
import { JsonResponse, StripePromotionCode } from "../../../types";

const getActivePromoCode = async (
  code: string
): Promise<StripePromotionCode | null> => {
  const {
    data: {
      getActivePromoCode: { response },
    },
  } = await client.query<{ getActivePromoCode: JsonResponse }>({
    query,
    variables: { code },
  });
  return response && JSON.parse(response);
};

export default getActivePromoCode;
