import { ListItem, useTheme } from "@ui-kitten/components";
import React from "react";
import { StyleSheet } from "react-native";

import { PlaidLinkItem } from "../../types";

interface Props {
  linkItem: PlaidLinkItem;
}

const styles = StyleSheet.create({
  listItem: {
    paddingVertical: 0,
  },
});

const PlaidLinkListItem = ({ linkItem }: Props) => {
  const theme = useTheme();
  const {
    metadata: { accounts, institution },
  } = linkItem;
  const account = accounts[0];
  return (
    <ListItem
      description={institution.name}
      disabled
      style={[
        styles.listItem,
        {
          borderStartColor: theme["text-hint-color"],
          borderStartWidth: 4,
        },
      ]}
      title={`${account.name} \u2022\u2022\u2022\u2022\u2022\u2022\u2022${account.mask}`}
    />
  );
};

export default PlaidLinkListItem;
