import { useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";
import { toast } from "react-toastify";

interface Props {
  linkToken: string;
  onExit: (error, metadata) => void;
  onSuccess: (public_token, metadata) => void;
}

const PlaidLink = ({ linkToken, onExit, onSuccess }: Props) => {
  const { open, ready } = usePlaidLink({
    onExit,
    onSuccess: (public_token, metadata) => {
      switch (metadata.accounts.length) {
        case 0:
          toast("Select Account is enabled", { type: "error" });
          break;
        case 1:
          onSuccess(public_token, metadata);
          break;
        default:
          toast("Multiple Accounts is enabled", { type: "error" });
          break;
      }
    },
    token: linkToken,
  });
  useEffect(() => {
    if (linkToken && ready) {
      open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkToken, ready]);
  return null;
};

export default PlaidLink;
