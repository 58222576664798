import { Button, Input, Text } from "@ui-kitten/components";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { View } from "react-native";
import { toast } from "react-toastify";
import * as yup from "yup";

import Separator from "../../components/Separator";

interface Props {
  initialValues: {
    email?: string;
    password?: string;
  };
  onSuccess: (values: { email: string; password: string }) => void;
  visible: boolean;
}

const EmailAndPassword = ({ initialValues, onSuccess, visible }: Props) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) =>
      onSuccess({ email: values.email, password: values.password }),
    validationSchema: yup.object().shape({
      email: yup.string().required("Required").email("Not a valid email"),
      password: yup
        .string()
        .required("Required")
        .min(8, "Must be eight or more characters"),
    }),
  });

  useEffect(() => {
    if (visible) {
      formik.resetForm({
        values: {
          email: initialValues.email || "",
          password: initialValues.password || "",
        },
      });
    }
  }, [visible]);
  useEffect(() => {
    if (formik.submitCount > 0 && !formik.isValid) {
      toast("Submission failed. Please fix the remaining errors.", {
        type: "error",
      });
    }
  }, [formik.isValid, formik.submitCount]);

  return (
    <View>
      <Separator />
      <Text category="p2">
        STEP <Text category="label">2</Text> OF <Text category="label">5</Text>
      </Text>
      <Text category="h5">Create your Talarium account</Text>
      <Separator size="small" />
      <Text appearance="hint">Enter the login information.</Text>
      <Separator />
      <Input
        caption={formik.touched.email && formik.errors.email}
        label="Email"
        onBlur={formik.handleBlur("email")}
        onChangeText={formik.handleChange("email")}
        onSubmitEditing={() => formik.handleSubmit()}
        status={
          formik.touched.email && formik.errors.email ? "danger" : "basic"
        }
        value={formik.values.email}
      />
      <Separator size="medium" />
      <Input
        caption={formik.touched.password && formik.errors.password}
        label="Add a password"
        onBlur={formik.handleBlur("password")}
        onChangeText={formik.handleChange("password")}
        onSubmitEditing={() => formik.handleSubmit()}
        placeholder="Must be eight or more characters"
        secureTextEntry
        status={
          formik.touched.password && formik.errors.password ? "danger" : "basic"
        }
        value={formik.values.password}
      />
      <Separator size={48} />
      <Button onPress={() => formik.handleSubmit()}>Continue</Button>
      <Separator />
    </View>
  );
};

export default EmailAndPassword;
