import { makeStyles } from "@material-ui/core/styles";
import { CardElement } from "@stripe/react-stripe-js";
import { Layout, Text, useTheme } from "@ui-kitten/components";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  caption: {
    marginTop: 4,
  },
  input: {
    marginEnd: 8,
    marginStart: 8,
  },
  inputContainer: {
    borderRadius: 4,
    borderWidth: 1,
    justifyContent: "center",
    minHeight: 40,
    overflow: "hidden",
    paddingBottom: 7,
    paddingEnd: 8,
    paddingStart: 8,
    paddingTop: 7,
  },
  label: {
    marginBottom: 4,
  },
});

const useStyles = makeStyles({
  input: {
    paddingLeft: 8,
    paddingRight: 8,
  },
});

const CardInput = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [state, setState] = useState({
    focus: false,
    hover: false,
    initialFocus: false,
  });

  const getLayoutLevel = () => {
    if (state.focus && state.initialFocus) {
      return "1";
    }
    if (state.hover) {
      return "3";
    }
    return "2";
  };

  const getBorderColor = () => {
    if (errorMessage) {
      return theme["border-danger-color-1"];
    }
    if (state.focus) {
      return theme["border-primary-color-1"];
    }
    return theme["border-basic-color-4"];
  };

  return (
    <View>
      <Layout
        level={getLayoutLevel()}
        style={[styles.inputContainer, { borderColor: getBorderColor() }]}
      >
        <div
          className={classes.input}
          onMouseEnter={() =>
            setState((prevState) => ({ ...prevState, hover: true }))
          }
          onMouseLeave={() =>
            setState((prevState) => ({
              ...prevState,
              hover: false,
              initialFocus: false,
            }))
          }
        >
          <CardElement
            onBlur={() =>
              setState((prevState) => ({
                ...prevState,
                focus: false,
                initialFocus: false,
              }))
            }
            onChange={({ error }) => setErrorMessage(error?.message)}
            onFocus={() =>
              setState((prevState) => ({
                ...prevState,
                focus: true,
                initialFocus: true,
              }))
            }
            options={{
              style: {
                base: {
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                  fontSize: "15px",
                },
                empty: {
                  color: theme["text-hint-color"],
                },
                invalid: {
                  color: theme["text-basic-color"],
                  iconColor: theme["color-danger-default"],
                },
              },
            }}
          />
        </div>
      </Layout>
      <Text category="c1" status="danger" style={styles.caption}>
        {errorMessage}
      </Text>
    </View>
  );
};

export default CardInput;
