import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Paper from "@material-ui/core/Paper";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button, Divider, Icon, Text, useTheme } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, View } from "react-native";

import Separator from "../../components/Separator";
import { products } from "../../constants";
import getFormattedAmount from "../../utils/getFormattedAmount";

interface Props {
  onSuccess: () => void;
}

const styles = StyleSheet.create({
  additionalLicensesPrice: {
    textAlign: "right",
  },
  additionalLicensesText: {
    flex: 1,
  },
  icon: {
    height: 24,
    width: 24,
  },
  paper: {
    marginEnd: 2,
    marginStart: 2,
  },
  planContainer: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  planHeader: {
    alignItems: "center",
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
    height: 72,
  },
  totalPriceText: {
    flex: 1,
  },
});

const PlanPickerScreen = ({ onSuccess }: Props) => {
  const theme = useTheme();
  return (
    <View>
      <Separator />
      <Text category="p2">
        STEP <Text category="label">3</Text> OF <Text category="label">5</Text>
      </Text>
      <Text category="h5">Pricing plan</Text>
      <Separator size="small" />
      <Text appearance="hint">
        Everything you need to get started at an affordable price.
      </Text>
      <Separator />
      <Paper>
        <View style={styles.planContainer}>
          <Separator />
          <View style={styles.planHeader}>
            <Text category="h5" status="primary">
              CORE
            </Text>
            <Text>{`Starting at ${getFormattedAmount(
              products.core.pricing.price
            )} / month`}</Text>
          </View>
          <Separator />
          <Divider />
          <View style={styles.row}>
            <Icon
              fill={theme["color-success-default"]}
              name="checkmark-outline"
              style={styles.icon}
            />
            <Separator horizontal size="small" />
            <Text>
              10 Monthly Active Users (MAU) with unlimited access to Talarium
            </Text>
          </View>
          <Divider />
          <View style={styles.row}>
            <Icon
              fill={theme["color-success-default"]}
              name="checkmark-outline"
              style={styles.icon}
            />
            <Separator horizontal size="small" />
            <Text style={styles.additionalLicensesText}>
              {`${getFormattedAmount(
                products.additionalLicenses.pricing.price
              )} / month for each additional MAU`}
            </Text>
          </View>
          <Divider />
          <View style={styles.row}>
            <Icon
              fill={theme["color-success-default"]}
              name="checkmark-outline"
              style={styles.icon}
            />
            <Separator horizontal size="small" />
            <Text>Training and Onboarding</Text>
          </View>
          <Divider />
          <View style={styles.row}>
            <Icon
              fill={theme["color-success-default"]}
              name="checkmark-outline"
              style={styles.icon}
            />
            <Separator horizontal size="small" />
            <View style={{ width: "100%" }}>
              <Text>Premium support</Text>
              <Text appearance="hint" category="p2">
                9AM to 5PM (PT) from Monday through Friday
              </Text>
            </View>
          </View>
        </View>
      </Paper>
      <Separator />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Text>What is an MAU?</Text>
        </AccordionSummary>
        <AccordionDetails>
          <Text>
            A Monthly Active User (MAU) is defined as any user that has
            connected to the Talarium servers by using the Talarium app within
            the current billing period. Thus you only pay more for our service
            on an as needed basis.
          </Text>
        </AccordionDetails>
      </Accordion>
      <Separator size={48} />
      <Button onPress={onSuccess}>Continue</Button>
      <Separator />
    </View>
  );
};

export default PlanPickerScreen;
