import { Auth } from "aws-amplify";
import { AUTH_TYPE, AWSAppSyncClient } from "aws-appsync";

import config from "../../config";

const client = new AWSAppSyncClient({
  auth: {
    credentials: () => Auth.currentCredentials(),
    type: AUTH_TYPE.AWS_IAM,
  },
  disableOffline: true,
  region: config.aws.aws_appsync_region,
  url: config.aws.aws_appsync_graphqlEndpoint,
});

export default client;
