import { Button as UiKittenButton, ButtonProps } from "@ui-kitten/components";
import React from "react";

import ListItemSpinnerAccessory from "../ListItemSpinnerAccessory";

export type Props = {
  loading?: boolean;
} & ButtonProps;

const Button = ({
  accessoryLeft,
  children,
  disabled,
  loading,
  style,
  ...props
}: Props) => {
  return (
    <UiKittenButton
      {...props}
      accessoryLeft={loading ? ListItemSpinnerAccessory : accessoryLeft}
      disabled={disabled || loading}
      style={style}
    >
      {children}
    </UiKittenButton>
  );
};

Button.defaultProps = {
  loading: undefined,
};

export default Button;
